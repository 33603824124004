@font-face {
   font-family: GothamNarrow;
   src: url("./fonts/GothamNarrow-Bold.otf");
   font-style: normal;
   font-weight: 900;
}

@font-face {
   font-family: GothamNarrow;
   src: url("./fonts/GothamNarrow-Book.otf");
   font-style: normal;
   font-weight: 500;
}

@font-face {
   font-family: GothamNarrow;
   src: url("./fonts/GothamNarrow-Medium.otf");
   font-style: normal;
   font-weight: 700;
}

@font-face {
   font-family: GothamNarrow;
   src: url("./fonts/GothamNarrow-Light.otf");
   font-style: normal;
   font-weight: 100;
}

@font-face {
   font-family: Beleren2016-Bold;
   src: url("./fonts/Beleren2016-Bold.woff");
}
body {
   margin: 0;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   overflow: hidden;
}

* {
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: -moz-none;
   -o-user-select: none;
   user-select: none;
}

input,
textarea {
   -webkit-user-select: text;
   -khtml-user-select: text;
   -moz-user-select: text;
   -o-user-select: text;
   user-select: text;
}

.transparentBG-dark {
   background: url("./images/transparentbg.jpg") top left repeat;
   background-size: 100px;
}

.transparentBG-light {
   background: url("./images/transparentbg-light.jpg") top left repeat;
   background-size: 100px;
}
